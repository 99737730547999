<template>
    <div class="stateSelect">
        <div class="mb-0" :class="{ 'is-error': error }">
            <v-select
                :options="states"
                label="name"
                v-model="localValue"
                @update:modelValue="handleChange($event)"
                :filterable="true"
                :multiple="true"
                :loading="isLoading"
                placeholder="Select States"
                :append-to-body="true"
            />
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import vSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';

    const props = defineProps({
        country: {
            type: Number,
            default: 1,
        },

        modelValue: {
            required: false,
            type: Array,
            default: () => [],
        },
    });

    const emit = defineEmits([
        'update:modelValue',
    ]);

    const isLoading = ref(false);
    const states = ref([]);

    const localValue = ref([...props.modelValue])

    const fetchStates = () => {
        isLoading.value = true;
        axios.get('/api/states?country_id=' + props.country).then(response => {
            console.log('states loaded');
            isLoading.value = false;
            states.value = response.data.data;
            console.log(states.value);
        });
    };

    const handleChange = (val) => {
        emit('update:modelValue', val);
    };

    onMounted(() => {
        fetchStates();
    });
</script>

<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
};
</script>
