import Errors from './errors.js'

export default class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.originalData = data

        for (const field in data) {
            this[field] = data[field]
        }

        this.errors = new Errors()
        this.state = 'none'
    }

    /**
     * Fetch all relevant data for the form.
     */
    getData() {
        const data = {}

        for (const property in this.originalData) {
            data[property] = this[property]
        }

        return data
    }

    /**
     * Reset the form fields.
     */
    reset() {
        for (const field in this.originalData) {
            this[field] = ''
        }

        this.clearErrors()
    }

    clearErrors() {
        this.errors.clear()

        this.state = 'none'
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url) {
        return this.submit('post', url)
    }

    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
    put(url) {
        return this.submit('put', url)
    }

    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
    patch(url) {
        return this.submit('patch', url)
    }

    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
    delete(url) {
        return this.submit('DELETE', url)
    }

    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     */
    submit(requestType, url) {
        this.state = 'processing'
        return new Promise((resolve, reject) => {
            axios[requestType](url, this.getData())
                .then(response => {
                    this.onSuccess()

                    resolve(response)
                })
                .catch(error => {
                    this.onFail(error.response.data)

                    reject(error.response)
                })
        })
    }

    /**
     * Handle a successful form submission.
     *
     * @param {object} data
     */
    onSuccess() {
        this.state = 'success'
    }

    /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail(data) {
        this.state = 'error'

        this.errors.record(data.errors)
    }

    /**
     * check the state of the form
     * none / loading / success / error
     */
    hasSuccess() {
        return this.state === 'success'
    }

    hasError() {
        return this.errors.any()
    }

    isProcessing() {
        return this.state === 'processing'
    }
}
