<template>
  <div class="form-group" :class="{ 'has-danger': errors.get('subject') }">
    <label>Subject *</label>
    <modal v-if="modalVisible">
      <template v-slot:body>
        <h4>Dynamic Fields</h4>
        <div v-html="fields.help"></div>
      </template>
      <template v-slot:footer>
        <button
          class="btn btn-secondary mr-auto"
          @click.prevent="modalVisible = false"
        >
          Close
        </button>
      </template>
    </modal>
    <input v-model="subjectField" type="hidden" name="subject" />
    <span @click="handleUpdate" @keydown.enter.prevent>
      <VueTrix
        class="subjectTrixField"
        v-if="!loading"
        ref="trixSubjectField"
        v-model="subjectField"
        @update:modelValue="handleUpdate"
        inputId="subjectTrix"
        placeholder=""
      />
    </span>
    <span
      class="form-control-feedback"
      v-show="errors.has('subject')"
      v-text="errors.get('subject')"
    ></span>
  </div>
</template>
<script>
import modal from "../components/modal.vue";
import dynamicQuickpitchFields from "../components/dynamic-quickpitch-fields.json";
import VueTrix from "../components/VueTrix.vue";
import Errors from "../form/errors.js";

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  props: {
    subject: {
      required: false,
      type: String,
      default: "",
    },
    errors: {
      required: false,
      type: Object,
      default: () => new Errors(),
    },
    modelValue: {
      required: false,
      type: String,
      default: "",
    },
    dynamic: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  components: {
    modal,
    VueTrix,
  },
  data() {
    return {
      modalVisible: false,
      subjectField: "",
      loading: true,
      position: "",
      fields: [],
    };
  },
  watch: {
    subject(value) {
      this.subjectField = value;
    },
  },
  created() {
    this.fields = dynamicQuickpitchFields;
    this.subjectField = this.modelValue;

    var vm = this;

    document.addEventListener("trix-initialize", function (event) {
      let input = document.getElementById("subjectTrix");
      let trixEditor = document.querySelector("trix-editor[input=subjectTrix]");

      // let the body trix load first. v-if will prevent the subject trix from loading until loading=false
      if (event.target.inputElement != input) {
        vm.loading = false;
        return;
      }

      trixEditor.addEventListener("paste", function (event) {
        let paste = (event.clipboardData || window.clipboardData).getData(
          "text",
        );
        paste = paste.replace(/<\/?[^>]+(>|$)/g, "");
        const selection = window.getSelection();
        if (!selection.rangeCount) return false;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        event.preventDefault();
      });
      trixEditor.addEventListener("keydown", function (event) {
        if (event.metaKey) {
          trixEditor.editor.deactivateAttribute("bold");
          trixEditor.editor.deactivateAttribute("italic");
          trixEditor.editor.deactivateAttribute("underline");
          trixEditor.editor.deactivateAttribute("strikethrough");
        }
      });
      input.name = "subject";

      if (vm.dynamic) {
        let dynamicHelpButton = dynamicQuickpitchFields["dynamic-help-button"];
        let dynamicSubject = dynamicQuickpitchFields["dynamic-subject"];

        trixEditor.toolbarElement
          .querySelector(".trix-button-group--text-tools")
          .insertAdjacentHTML("afterend", dynamicHelpButton);
        trixEditor.toolbarElement
          .querySelector(".trix-button-group--text-tools")
          .insertAdjacentHTML("beforeend", dynamicSubject);

        document
          .getElementById("dynamicHelpButton")
          .addEventListener("click", function (event) {
            event.preventDefault();
            vm.modalVisible = !vm.modalVisible;
          });
      }

      if (vm.subject) {
        vm.$refs.trixSubjectField.$refs.trix.value = vm.subject;
      }
    });
  },
  methods: {
    handleUpdate(event) {
      this.position =
        this.$refs.trixSubjectField.$refs.trix.editor.getPosition();
      if (event.target && event.target.attributes["data-trix-attribute"]) {
        let trixAttribute = event.target.attributes["data-trix-attribute"];
        let field = dynamicQuickpitchFields[trixAttribute.value];
        let attachment = new Trix.Attachment({ content: field });
        if (field) {
          this.$refs.trixSubjectField.$refs.trix.editor.setSelectedRange(
            this.position,
          );
          this.$refs.trixSubjectField.$refs.trix.editor.insertAttachment(
            attachment,
          );
        }
        if (trixAttribute.value == "booker") {
          this.$refs.trixSubjectField.$refs.trix.editor.insertString(",");
        }
      }
      this.$emit("update:modelValue", this.subjectField);
    },
    showDynamicFieldsModal() {
      this.modalVisible = !this.modalVisible;
    },
  },
};
</script>

<style scoped>
.trix-content {
  overflow-y: hidden;
}
</style>
