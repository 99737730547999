<template>
  <span @click="handleUpdate">
    <VueTrix
      v-if="!loading"
      ref="trixPreview"
      v-model="trixBody"
      @update:modelValue="handleUpdate"
      inputId="bodyTrix"
      placeholder=""
    />
  </span>
</template>

<script>
import Trix from "trix";
import fields from "./dynamic-quickpitch-fields.json";
import VueTrix from "./VueTrix.vue";

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  props: {
    body: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      trixBody: "",
      position: "",
    };
  },
  components: {
    VueTrix,
  },
  mounted() {
    this.loading = false;
    const setTrixBody = new Promise((resolve) => {
      this.trixBody = this.modelValue;
      resolve(this.body);
    });
    setTrixBody.then((body) => {
      this.$refs.trixPreview.$refs.trix.value = body;
    });
    var vm = this;

    var alerted = false;

    document.addEventListener("trix-initialize", function (event) {
      let input = document.getElementById("bodyTrix");
      if (event.target.inputElement != input) {
        return;
      }
      var dynamicTextButton = fields["dynamic-dropdown"];
      input.name = "body";

      let trixToolbar = vm.$refs.trixPreview.$refs.trix.toolbarElement;
      trixToolbar
        .querySelector(".trix-button-group--text-tools")
        .insertAdjacentHTML("afterbegin", dynamicTextButton);

      // create the dropdown button manually because it does not exist on page load
      $(".dropdown-toggle").dropdown();
    });

    document.addEventListener("trix-attachment-add", function (event) {
      if (event.attachment.attachment.preloadingURL) {
        event.attachment.remove();
        if (alerted === false) {
          alert(
            "Image attachments are not supported. It is best to use simple text and links when initially reaching out to venues for booking consideration.",
          );

          alerted = true;
        }
      }
    });
  },
  methods: {
    handleUpdate(event) {
      this.position = this.$refs.trixPreview.$refs.trix.editor.getPosition();
      var vm = this;

      if (event.target && event.target.attributes["data-trix-attribute"]) {
        let trixAttribute = event.target.attributes["data-trix-attribute"];
        let field = fields[trixAttribute.value];
        let attachment = new Trix.Attachment({ content: field });
        if (field) {
          vm.$refs.trixPreview.$refs.trix.editor.setSelectedRange(vm.position);
          vm.$refs.trixPreview.$refs.trix.editor.insertAttachment(attachment);
        }

        if (trixAttribute.value == "booker") {
          vm.$refs.trixPreview.$refs.trix.editor.insertString(",");
        }

        // dropdown is not closing itself
        $(".dropdown-toggle").dropdown("hide");
      }

      this.$emit("update:modelValue", this.trixBody);
    },
  },
};
</script>
